@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    html, body {
        @apply bg-white text-[#433c3d] font-sans m-0 min-h-screen;
    }

    .form-label {
        @apply block text-sm leading-5 text-slate-500;
    }

    [type=text], [type=email], [type=url], [type=password], [type=number], [type=date], [type=datetime-local], [type=month], [type=search], [type=tel], [type=time], [type=week], textarea, select, select[multiple] {
        @apply w-full rounded-md border-slate-200 border placeholder-gray-300;
    }

    [type=text], [type=email], [type=url], [type=password], [type=number], [type=date], [type=datetime-local], [type=month], [type=search], [type=tel], [type=time], [type=week], [multiple], textarea, select {
        @apply appearance-none bg-white border py-2 px-3 text-base;
    }

    .btn {
        @apply rounded border border-transparent px-4 py-2 text-sm font-semibold uppercase tracking-widest transition-all;
    }

    .btn-blue {
        @apply bg-blue text-white shadow-none;
    }

    .btn-mobile {
        @apply fixed bottom-0 bg-blue rounded-none w-full left-0 right-0 -mb-1 px-6 pt-4 pb-5 font-bold text-white text-center z-10;
    }

    .disabled, [disabled], :disabled {
        @apply bg-gray-200 cursor-not-allowed text-gray-400;

        & svg {
            @apply fill-gray-400;
        }
    }

    .peer:checked ~ label .peer-checked\:block {
        @apply block;
    }

    input:focus-visible {
        @apply outline-blue;
    }

    // Toggle Checkbox HTML

    input:checked ~ .dot {
        @apply translate-x-full;
    }

    input:checked ~ .pill {
        @apply bg-blue;
    }

    input:not(:checked) ~ .pill {
        @apply bg-gray-200 border border-slate-200;
    }

    .form-select + .select2,
    .directory-select + .select2 {
        @apply flex self-stretch;

        .selection {
            @apply w-full;
        }

        .select2-selection {
            @apply bg-blue-light border-0 rounded-none outline-none appearance-none shadow-none h-full pr-2 md:text-lg;

            span {
                @apply flex items-start h-full font-semibold flex-col justify-center;
            }

            .select2-selection__arrow {
                @apply md:right-3 top-0;

                b {
                    @apply border-t-blue;
                }
            }
        }

        &.select2-container--open .select2-selection .select2-selection__arrow b {
            @apply border-b-blue;
        }
    }

    .pagination {
        @apply flex justify-center list-none p-0;

        .page-item {
            @apply overflow-hidden shadow;

            .page-link {
                @apply font-bold p-3 block no-underline border-t border-l border-b bg-white text-blue;

                &:hover {
                    @apply bg-blue-light;

                }
            }

            &:hover {

            }

            &:first-child {
                @apply rounded-l;

                .page-link {
                    @apply rounded-l;

                    &:hover {

                    }
                }
            }

            &:nth-child(2) {
                .page-link {
                    &:hover {

                    }
                }
            }

            &:nth-last-child(2) {
                .page-link {
                    &:hover {

                    }
                }
            }

            &:last-child {
                @apply rounded-r;

                .page-link {
                    @apply rounded-r border-r;

                    &:hover {

                    }
                }
            }

            &.disabled {
                .page-link {
                    @apply bg-gray-100 text-gray-400;

                    &:hover {

                    }
                }
            }

            &.active {
                .page-link {
                    @apply bg-blue text-white;

                    &:hover {

                    }
                }
            }
        }
    }
}

.directory-select + .select2 {
    width: 80px !important;
    border: 1px solid #0F52BA;
    border-radius: .25rem;
    overflow: hidden;

    @media (max-width:768px) {
        width: fit-content !important;
    }

    .select2-selection__arrow b {
        border-width: 8px 6px 0 6px;
    }

    &.select2-container--open .select2-selection__arrow b {
        border-width: 0 6px 8px 6px;
    }
}

.form-select + .select2 {
    width: 25% !important;

    @media (max-width:768px) {
        width: fit-content !important;
    }

    .select2-selection__arrow b {
        border-width: 8px 6px 0 6px;
    }

    &.select2-container--open .select2-selection__arrow b {
        border-width: 0 6px 8px 6px;
    }
}

span.select2-container--default {
    .select2-dropdown {
        border-radius: 0;
        border-color: #0F52BA;
        font-weight: 600;

        ul.select2-results__options {
            max-height: 230px;
        }

        li {
            border-color: #0F52BA;
        }

        &.select2-dropdown--above li {
            border-bottom-width: 1px;
        }

        &.select2-dropdown--below li {
            border-top-width: 1px;
        }
    }

    .select2-results__option--selected {
        background-color: #e8fffd;
    }

    .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: #0F52BA;

        div {
            color: #ffffff !important;
        }
    }
}

div.swal2-popup {
    padding: 0;
    overflow: hidden;
}

div.swal2-header {
    padding: 1.25em 3.05em 0;
}

div.swal2-content {
    padding: 0 2.85em;

    @media (max-width: 768px) {
        padding: 0 1.85em;
    }
}

div.swal2-container.swal2-bottom {
    width: 100%;
    padding: 0;

    div.swal2-popup {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.swal2-actions button,
.swal2-actions button:focus {
    padding: 1em 2em;
    box-shadow: none;
    outline: none;
    margin: 0;
    display: block;
    width: 100%;
    border-radius: 0 !important;

    &.swal2-confirm {
        background-color: #0F52BA;
    }
}

.pulse-down {
    border-radius: 100%;
    background-color: rgba(239,68,68, 1);
    box-shadow: 0 0 0 0 rgba(239,68,68, 1);
    transform: scale(1.1);
    animation: pulse_down 2s infinite;
}

@keyframes pulse_down {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(239,68,68, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 5px rgba(239,68,68, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(239,68,68, 0);
    }
}

.pulse-delay {
    border-radius: 100%;
    background-color: rgba(254,134,1, 1);
    box-shadow: 0 0 0 0 rgba(254,134,1, 1);
    transform: scale(1.1);
    animation: pulse_delay 2s infinite;
}

@keyframes pulse_delay {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(254,134,1, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 5px rgba(254,134,1, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(254,134,1, 0);
    }
}

.pulse-maintenance {
    border-radius: 100%;
    background-color: rgba(124,124,124, 1);
    box-shadow: 0 0 0 0 rgba(124,124,124, 1);
    transform: scale(1.1);
    animation: pulse_maintenance 2s infinite;
}

@keyframes pulse_maintenance {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(124,124,124, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 5px rgba(124,124,124, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(124,124,124, 0);
    }
}

.pulse-active {
    border-radius: 100%;
    background-color: rgba(34, 197, 94, 1);
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
    transform: scale(1.1);
    animation: pulse_active 2s infinite;
}

@keyframes pulse_active {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 5px rgba(34, 197, 94, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(34, 197, 94, 0);
    }
}
